<template>
  <div class="page-bg">
    <div class="content-container submitted-container">
      <div class="overlay-content submitted">
        <div class="overlay-header submitted-header">
          <img class="submitted-logo"
            srcset="@/assets/images/FHBColorLogo.png 1x, @/assets/images/FHBColorLogo-2x.png 2x"
            src="@/assets/images/FHBColorLogo-2x.png" alt="First Hawaiian Bank home logo" />
        </div>
        <MainId />
        <div role="main">
          <div class="overlay-splash submitted-splash">
            <img srcset="@/assets/images/submittedSplash.png 1x, @/assets/images/submittedSplash-2x.png 2x"
              src="@/assets/images/submittedSplash-2x.png" alt="" />
          </div>
          <div class="overlay-title submitted-title">
            Business Credit Application Status
          </div>
          <div class="overlay-subtitle submitted-subtitle">
            The information below shows the following business owners status regarding the application. Business owners who have a check next to their name signifies that they have read and signed the disclosure.
          </div>
          <div class="v-spacer-20" />
        </div>

        <table>
          <tr>
            <th class="bottom-border debtor-spacing">Business Owner</th>
            <th class="bottom-border amt-spacing">Signature Signed</th>
          </tr>
          <tr v-if="this.owner_first_name && !owner_is_filer">
            <td>
              {{ this.owner_first_name }}
              {{ this.owner_last_name }}
            </td>
            <td v-if="this.owner_status">
              <img srcset="@/assets/images/green_check.png 1x, @/assets/images/green_check_2x.png 2x"
                src="@/assets/images/green_check_2x.png" alt="checkmark icon" height="24" />
            </td>
            <td v-else>

            </td>
          </tr>

          <tr v-for="i in this.num_other_owners" :key="i">
            <td>

              {{ this.other_owners_first_name[i - 1] }}
              {{ this.other_owners_last_name[i - 1] }}
            </td>
            <td v-if="this.other_owners_status[i - 1]">
              <img srcset="@/assets/images/green_check.png 1x, @/assets/images/green_check_2x.png 2x"
                src="@/assets/images/green_check_2x.png" alt="checkmark icon" height="24" />
            </td>
            <td v-else>

            </td>
          </tr>
        </table>

        <div class="v-spacer-40" />

        <div class="overlay-title">
          Pending Signatures:
        </div>
        <div class="overlay-title">
          {{ this.num_pending }}
        </div>

        <div class="v-spacer-40" />

        <h2 class="submitted-txt-sm">
          <strong>Got questions?</strong>
        </h2>
        <div class="submitted-txt-sm">
          Please email <a href="mailto:businessbanker@fhb.com">businessbanker@fhb.com</a>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MainId from '@/components/MainId'
import UserInfoApi from '@/app/ll-commercial-api'


export default {
  name: 'Submit Application',
  components: {
    MainId
  },
  data() {
    return {

      owner_first_name: '',
      owner_last_name: '',
      owner_status: null,
      owner_is_filer: false,
      other_owners_first_name: [],
      other_owners_last_name: [],
      other_owners_status: [],
      num_other_owners: 0,
      num_pending: 0,
    }
  },
  created() {
    this.setCurrentPage(3)
    this.setValidatedNavPage({ name: 'owner-status', isValid: 1 });
  },
  mounted() {
    this.getOwners()

  },
  computed: mapGetters('businessCreditOwner', ['type', 'curPage', 'autopay', 'receive_funds', 'complete_docs', 'application_id', 'jwt_token', 'appId', 'ownerId', 'submissionID']),
  methods: {
    ...mapActions("businessCreditOwner",
      ['setValidatedNavPage', 'setCurrentPage']
    ),
    async getOwners() {
      const respVerify = await UserInfoApi.getCreditAppOwner(this.appId, this.ownerId, localStorage.getItem('token'))
      if (respVerify.data.response.owner !== null) {
        this.owner_status = respVerify.data.response.owner.signature_signed_at || respVerify.data.response.owner.personal_financial_submitted_at
        this.owner_is_filer = respVerify.data.response.owner_is_filer
        this.owner_first_name = respVerify.data.response.owner.first_name
        this.owner_last_name = respVerify.data.response.owner.last_name
        if (respVerify.data.response.owner.signature_signed_at == null && !this.owner_is_filer && !respVerify.data.response.owner.personal_financial_submitted_at) {
          this.num_pending += 1
        }

      }
      if (respVerify.data.response.other_owners !== null) {
        for (let i = 0; i < respVerify.data.response.other_owners.length; i++) {
          this.other_owners_first_name.push(respVerify.data.response.other_owners[i].first_name)
          this.other_owners_last_name.push(respVerify.data.response.other_owners[i].last_name)
          let signedAt = respVerify.data.response.other_owners[i].owner_signed_at
          if ((signedAt == null || signedAt == "") && respVerify.data.response.other_owners[i].personal_financial_submitted_at) {
            signedAt = respVerify.data.response.other_owners[i].personal_financial_submitted_at
          }
          this.other_owners_status.push(signedAt)
          if (respVerify.data.response.other_owners[i].owner_signed_at == null && respVerify.data.response.other_owners[i].personal_financial_submitted_at == null) {
            this.num_pending += 1
          }
          this.num_other_owners += 1
        }

      }
    },
  },


}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

/* html, body {
   height: 100%;
   width: 100%;
   margin: 0;
   padding: 0;
   background: #FDF8F4;
  opacity: 0.95;
} */
.page-bg {
  background-color: $orangeFaint;
  height: 100%;
  min-height: 100vh;
}

a {
  color: $black;
  text-decoration: none;
}

.submitted-subtitle {
  font-size: 1.1rem;
  margin-top: 0.25em;
}

.submitted-txt-sm {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.25em;
}

.appid-txt {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: center;
}

.spinner img {
  -webkit-animation: spin 5s linear infinite;
  -moz-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}

@-moz-keyframes spin {
  80% {
    -moz-transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@-webkit-keyframes spin {
  80% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes spin {
  80% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.overlay-splash {
  margin: 2em auto 0;
  position: relative;
  width: 80px;
  z-index: 2;
}

.overlay-splash+.overlay-title {
  margin-bottom: 10px;
  margin-top: 25px;
}

.overlay-splash .spinner {
  height: 80px;
  position: absolute;
  top: 4px;
  width: 80px;
  z-index: 10;
}

.overlay-splash .logo {
  z-index: 1;
  position: relative;
}

.overlay-splash .spinner img {
  height: auto;
  width: 80px;
}

.overlay-splash .logo img {
  height: auto;
  width: 60px;
  animation: fadeIn 5s linear infinite;
  -webkit-animation: fadeIn 5s linear infinite;
  -moz-animation: fadeIn 5s linear infinite;
  -o-animation: fadeIn 5s linear infinite;
  -ms-animation: fadeIn 5s linear infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.overlay-body {
  position: relative;
  z-index: 40;
}

table {
  border-collapse: collapse;
}

.debtor-spacing {
  width: 400px;
}


.amt-spacing {
  width: 250px;
}
</style>
